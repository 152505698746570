import React from "react"
import { Layout, Header } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const AlgemeneVoorwaarden = () => {
  return (
    <Layout>
      <GatsbySeo
        title="Algemene Voorwaarden"
        description="Algemene Voorwaarden To Be On The Web"
      />
      <Header />
      <div className="max-w-screen-xl mx-auto p-4 lg:px-8">
        <div className="-mx-4 flex max-w-5xl flex-wrap">
          <div className="w-full px-4 md:w-2/3">
            <h2 className="font-heading mb-2 text-4xl leading-tight">
              Algemene Voorwaarden
            </h2>
            <div className="prose prose-zinc">
              <strong>Inhoudsopgave</strong>: <br />
              Artikel 1 - Definities
              <br /> Artikel 2 - Identiteit van de ondernemer <br />
              Artikel 3 - Toepasselijkheid <br />
              Artikel 4 - Het aanbod <br />
              Artikel 5 - De overeenkomst <br />
              Artikel 6 - De prijs <br />
              Artikel 7 - Conformiteit en garantie <br />
              Artikel 8 - Levering en uitvoering <br />
              Artikel 9 - Duurtransacties: duur, opzegging en verlenging <br />
              Artikel 10 - Betaling <br />
              Artikel 11 - Geschillen <br />
              Artikel 12 - Aanvullende of afwijkende bepalingen
              <h2>Artikel 1 - Definities</h2>
              In deze voorwaarden wordt verstaan onder:
              <ol>
                <li>
                  <strong>Consument:</strong> de natuurlijke persoon die niet
                  handelt in de uitoefening van beroep of bedrijf en een
                  overeenkomst op afstand aangaat met de ondernemer;
                </li>
                <li>
                  <strong>Dag:</strong> kalenderdag;
                </li>
                <li>
                  <strong>Duurtransactie:</strong> een overeenkomst op afstand
                  met betrekking tot een reeks van producten en/of diensten,
                  waarvan de leverings- en/of afnameverplichting in de tijd is
                  gespreid;
                </li>
                <li>
                  <strong>Duurzame gegevensdrager:</strong> elk middel dat de
                  consument of ondernemer in staat stelt om informatie die aan
                  hem persoonlijk is gericht, op te slaan op een manier die
                  toekomstige raadpleging en ongewijzigde reproductie van de
                  opgeslagen informatie mogelijk maakt;
                </li>
                <li>
                  <strong>Ondernemer:</strong> de natuurlijke of rechtspersoon
                  die lid is van de Nederlandse Thuiswinkel Organisatie en
                  producten en/of diensten op afstand aan consumenten aanbiedt;
                </li>
                <li>
                  <strong>Overeenkomst op afstand:</strong> een overeenkomst
                  waarbij in het kader van een door de ondernemer georganiseerd
                  systeem voor verkoop op afstand van producten en/of diensten,
                  tot en met het sluiten van de overeenkomst uitsluitend gebruik
                  gemaakt wordt van één of meer technieken voor communicatie op
                  afstand;
                </li>
                <li>
                  <strong>Techniek voor communicatie op afstand:</strong> middel
                  dat kan worden gebruikt voor het sluiten van een overeenkomst,
                  zonder dat consument en ondernemer gelijktijdig in dezelfde
                  ruimte zijn samengekomen;
                </li>
              </ol>
              <h2>Artikel 2 - Identiteit van de ondernemer</h2>
              <strong>Naam onderneming:</strong> To Be Free
              <br />
              <strong>Handelend onder de naam:</strong> To Be On The Web
              <br />
              <strong>Adres:</strong> Kievit 12b - 14, 5111 HD Baarle-Nassau
              <br />
              <strong>Telefoonnummer:</strong> 06 - 294 78 385
              <br />
              <strong>Bereikbaarheid:</strong> maandag t/m vrijdag van 9:00 uur
              tot 17:00 uur
              <br />
              <strong>E-mailadres:</strong> info@tobeontheweb.nl
              <br />
              <strong>KvK-nummer:</strong> 20103275
              <br />
              <strong>Btw-identificatienummer:</strong> NL001735717B82
              <br />
              <h2>Artikel 3 - Toepasselijkheid</h2>
              <ol>
                <li>
                  Deze algemene voorwaarden zijn van toepassing op elk aanbod
                  van de ondernemer en op elke tot stand gekomen overeenkomst op
                  afstand tussen ondernemer en consument.
                </li>
                <li>
                  Voordat de overeenkomst op afstand wordt gesloten, wordt de
                  tekst van deze algemene voorwaarden aan de consument
                  beschikbaar gesteld. Indien dit redelijkerwijs niet mogelijk
                  is, zal voordat de overeenkomst op afstand wordt gesloten,
                  worden aangegeven dat de algemene voorwaarden bij de
                  ondernemer zijn in te zien en zij op verzoek van de consument
                  zo spoedig mogelijk kosteloos worden toegezonden.
                </li>
                <li>
                  Indien de overeenkomst op afstand elektronisch wordt gesloten,
                  kan in afwijking van het vorige lid en voordat de overeenkomst
                  op afstand wordt gesloten, de tekst van deze algemene
                  voorwaarden langs elektronische weg aan de consument ter
                  beschikking worden gesteld op zodanige wijze dat deze door de
                  consument op een eenvoudige manier kan worden opgeslagen op
                  een duurzame gegevensdrager. Indien dit redelijkerwijs niet
                  mogelijk is, zal voordat de overeenkomst op afstand wordt
                  gesloten, worden aangegeven waar van de algemene voorwaarden
                  langs elektronische weg kan worden kennisgenomen en dat zij op
                  verzoek van de consument langs elektronische weg of op andere
                  wijze kosteloos zullen worden toegezonden.
                </li>
                <li>
                  Voor het geval dat naast deze algemene voorwaarden tevens
                  specifieke product- of dienstenvoorwaarden van toepassing
                  zijn, is het tweede en derde lid van overeenkomstige
                  toepassing en kan de consument zich in geval van
                  tegenstrijdige algemene voorwaarden steeds beroepen op de
                  toepasselijke bepaling die voor hem het meest gunstig is.
                </li>
                <li>
                  Indien één of meerdere bepalingen in deze algemene voorwaarden
                  op enig moment geheel of gedeeltelijk nietig zijn of
                  vernietigd worden, dan blijft de overeenkomst en deze
                  voorwaarden voor het overige in stand en zal de betreffende
                  bepaling in onderling overleg onverwijld vervangen worden door
                  een bepaling dat de strekking van het oorspronkelijke zoveel
                  mogelijk benaderd.
                </li>
                <li>
                  Situaties die niet in deze algemene voorwaarden zijn geregeld,
                  dienen te worden beoordeeld ‘naar de geest’ van deze algemene
                  voorwaarden.
                </li>
                <li>
                  Onduidelijkheden over de uitleg of inhoud van één of meerdere
                  bepalingen van onze voorwaarden, dienen uitgelegd te worden
                  ‘naar de geest’ van deze algemene voorwaarden.
                </li>
              </ol>
              <h2>Artikel 4 - Het aanbod</h2>
              <ol>
                <li>
                  Indien een aanbod een beperkte geldigheidsduur heeft of onder
                  voorwaarden geschiedt, wordt dit nadrukkelijk in het aanbod
                  vermeld.
                </li>
                <li>
                  Het aanbod is vrijblijvend. De ondernemer is gerechtigd het
                  aanbod te wijzigen en aan te passen.
                </li>
                <li>
                  Het aanbod bevat een volledige en nauwkeurige omschrijving van
                  de aangeboden producten en/of diensten. De beschrijving is
                  voldoende gedetailleerd om een goede beoordeling van het
                  aanbod door de consument mogelijk te maken. Als de ondernemer
                  gebruik maakt van afbeeldingen zijn deze een waarheidsgetrouwe
                  weergave van de aangeboden producten en/of diensten.
                  Kennelijke vergissingen of kennelijke fouten in het aanbod
                  binden de ondernemer niet.
                </li>
                <li>
                  Alle afbeeldingen, specificaties gegevens in het aanbod zijn
                  indicatie en kunnen geen aanleiding zijn tot schadevergoeding
                  of ontbinding van de overeenkomst.
                </li>
                <li>
                  Afbeeldingen bij producten zijn een waarheidsgetrouwe weergave
                  van de aangeboden producten. Ondernemer kan niet garanderen
                  dat de weergegeven kleuren exact overeenkomen met de echte
                  kleuren van de producten.
                </li>
                <li>
                  Elk aanbod bevat zodanige informatie, dat voor de consument
                  duidelijk is wat de rechten en verplichtingen zijn, die aan de
                  aanvaarding van het aanbod zijn verbonden. Dit betreft in het
                  bijzonder:
                  <ul>
                    <li>de prijs inclusief belastingen;</li>
                    <li>de eventuele kosten van aflevering;</li>
                    <li>
                      de wijze waarop de overeenkomst tot stand zal komen en
                      welke handelingen daarvoor nodig zijn;
                    </li>
                    <li>
                      de wijze van betaling, aflevering en uitvoering van de
                      overeenkomst;
                    </li>
                    <li>
                      de termijn voor aanvaarding van het aanbod, dan wel de
                      termijn waarbinnen de ondernemer de prijs garandeert;
                    </li>
                    <li>
                      de hoogte van het tarief voor communicatie op afstand
                      indien de kosten van het gebruik van de techniek voor
                      communicatie op afstand worden berekend op een andere
                      grondslag dan het reguliere basistarief voor het gebruikte
                      communicatiemiddel;
                    </li>
                    <li>
                      of de overeenkomst na de totstandkoming wordt
                      gearchiveerd, en zo ja op welke wijze deze voor de
                      consument te raadplegen is;
                    </li>
                    <li>
                      de manier waarop de consument, voor het sluiten van de
                      overeenkomst, de door hem in het kader van de overeenkomst
                      verstrekte gegevens kan controleren en indien gewenst
                      herstellen;
                    </li>
                    <li>
                      de eventuele andere talen waarin, naast het Nederlands, de
                      overeenkomst kan worden gesloten;
                    </li>
                    <li>
                      de gedragscodes waaraan de ondernemer zich heeft
                      onderworpen en de wijze waarop de consument deze
                      gedragscodes langs elektronische weg kan raadplegen;
                    </li>
                    <li>
                      de minimale duur van de overeenkomst op afstand in geval
                      van een duurtransactie.
                    </li>
                  </ul>
                </li>
              </ol>
              <h2>Artikel 5 - De overeenkomst</h2>
              <ol>
                <li>
                  De overeenkomst komt, onder voorbehoud van het bepaalde in lid
                  4, tot stand op het moment van aanvaarding door de consument
                  van het aanbod en het voldoen aan de daarbij gestelde
                  voorwaarden.
                </li>
                <li>
                  Indien de consument het aanbod langs elektronische weg heeft
                  aanvaard, bevestigt de ondernemer onverwijld langs
                  elektronische weg de ontvangst van de aanvaarding van het
                  aanbod. Zolang de ontvangst van deze aanvaarding niet door de
                  ondernemer is bevestigd, kan de consument de overeenkomst
                  ontbinden.
                </li>
                <li>
                  Indien de overeenkomst elektronisch tot stand komt, treft de
                  ondernemer passende technische en organisatorische maatregelen
                  ter beveiliging van de elektronische overdracht van data en
                  zorgt hij voor een veilige webomgeving. Indien de consument
                  elektronisch kan betalen, zal de ondernemer daartoe passende
                  veiligheidsmaatregelen in acht nemen.
                </li>
                <li>
                  De ondernemer kan zich – binnen wettelijke kaders – op de
                  hoogte stellen of de consument aan zijn
                  betalingsverplichtingen kan voldoen, alsmede van al die feiten
                  en factoren die van belang zijn voor een verantwoord aangaan
                  van de overeenkomst op afstand. Indien de ondernemer op grond
                  van dit onderzoek goede gronden heeft om de overeenkomst niet
                  aan te gaan, is hij gerechtigd gemotiveerd een bestelling of
                  aanvraag te weigeren of aan de uitvoering bijzondere
                  voorwaarden te verbinden.
                </li>
                <li>
                  De ondernemer zal bij het product of dienst aan de consument
                  de volgende informatie, schriftelijk of op zodanige wijze dat
                  deze door de consument op een toegankelijke manier kan worden
                  opgeslagen op een duurzame gegevensdrager, meesturen:
                  <ul>
                    <li>
                      het bezoekadres van de vestiging van de ondernemer waar de
                      consument met klachten terecht kan;
                    </li>
                    <li>
                      de informatie over garanties en bestaande service na
                      aankoop;
                    </li>
                    <li>
                      de in artikel 4 lid 3 van deze voorwaarden opgenomen
                      gegevens, tenzij de ondernemer deze gegevens al aan de
                      consument heeft verstrekt vóór de uitvoering van de
                      overeenkomst;
                    </li>
                    <li>
                      de vereisten voor opzegging van de overeenkomst indien de
                      overeenkomst een duur heeft van meer dan één jaar of van
                      onbepaalde duur is;
                    </li>
                    <li>
                      in geval van een duurtransactie is de bepaling in het
                      vorige lid slechts van toepassing op de eerste levering.
                    </li>
                  </ul>
                </li>
                <li>
                  In geval van een duurtransactie is de bepaling in het vorige
                  lid slechts van toepassing op de eerste levering.
                </li>
                <li>
                  Iedere overeenkomst wordt aangegaan onder de opschortende
                  voorwaarden van voldoende beschikbaarheid van de betreffende
                  producten.
                </li>
              </ol>
              <h2>Artikel 6 - De prijs</h2>
              <ol>
                <li>
                  Gedurende de in het aanbod vermelde geldigheidsduur worden de
                  prijzen van de aangeboden producten en/of diensten niet
                  verhoogd, behoudens prijswijzigingen als gevolg van
                  veranderingen in btw-tarieven.
                </li>
                <li>
                  In afwijking van het vorige lid kan de ondernemer producten of
                  diensten waarvan de prijzen gebonden zijn aan schommelingen op
                  de financiële markt en waar de ondernemer geen invloed op
                  heeft, met variabele prijzen aanbieden. Deze gebondenheid aan
                  schommelingen en het feit dat eventueel vermelde prijzen
                  richtprijzen zijn, worden bij het aanbod vermeld.
                </li>
                <li>
                  Prijsverhogingen binnen 3 maanden na de totstandkoming van de
                  overeenkomst zijn alleen toegestaan indien zij het gevolg zijn
                  van wettelijke regelingen of bepalingen.
                </li>
                <li>
                  Prijsverhogingen vanaf 3 maanden na de totstandkoming van de
                  overeenkomst zijn alleen toegestaan indien de ondernemer dit
                  bedongen heeft en: deze het gevolg zijn van wettelijke
                  regelingen of bepalingen; of de consument de bevoegdheid heeft
                  de overeenkomst op te zeggen met ingang van de dag waarop de
                  prijsverhoging ingaat.
                </li>
                <li>
                  De in het aanbod van producten of diensten genoemde prijzen
                  zijn inclusief btw.
                </li>
                <li>
                  Alle prijzen zijn onder voorbehoud van druk – en zetfouten.
                  Voor de gevolgen van druk – en zetfouten wordt geen
                  aansprakelijkheid aanvaard. Bij druk – en zetfouten is de
                  ondernemer niet verplicht het product volgens de foutieve
                  prijs te leveren.
                </li>
              </ol>
              <h2>Artikel 7 - Conformiteit en Garantie</h2>
              <ol>
                <li>
                  De ondernemer staat er voor in dat de producten en/of diensten
                  voldoen aan de overeenkomst, de in het aanbod vermelde
                  specificaties, aan de redelijke eisen van deugdelijkheid en/of
                  bruikbaarheid en de op de datum van de totstandkoming van de
                  overeenkomst bestaande wettelijke bepalingen en/of
                  overheidsvoorschriften. Indien overeengekomen staat de
                  ondernemer er tevens voor in dat het product geschikt is voor
                  ander dan normaal gebruik.
                </li>
                <li>
                  Een door de ondernemer, fabrikant of importeur verstrekte
                  garantie doet niets af aan de wettelijke rechten en
                  vorderingen die de consument op grond van de overeenkomst
                  tegenover de ondernemer kan doen gelden.
                </li>
                <li>
                  Eventuele gebreken of verkeerd geleverde producten dienen
                  binnen 14 dagen na levering aan de ondernemer schriftelijk te
                  worden gemeld. Terugzending van de producten dient te
                  geschieden in de originele verpakking en in nieuwstaat
                  verkerend.
                </li>
                <li>
                  De garantietermijn van de ondernemer komt overeen met de
                  fabrieksgarantietermijn. De ondernemer is echter te nimmer
                  verantwoordelijk voor de uiteindelijke geschiktheid van de
                  producten voor elke individuele toepassing door de consument,
                  noch voor eventuele adviezen ten aanzien van het gebruik of de
                  toepassing van de producten.
                </li>
                <li>
                  De garantie geldt niet indien:
                  <ul>
                    <li>
                      De consument de geleverde producten zelf heeft gerepareerd
                      en/of bewerkt of door derden heeft laten repareren en/of
                      bewerken;
                    </li>
                    <li>
                      De geleverde producten aan abnormale omstandigheden zijn
                      blootgesteld of anderszins onzorgvuldig worden behandeld
                      of in strijd zijn met de aanwijzingen van de ondernemer
                      en/of op de verpakking behandeld zijn;
                    </li>
                    <li>
                      De ondeugdelijkheid geheel of gedeeltelijk het gevolg is
                      van voorschriften die de overheid heeft gesteld of zal
                      stellen ten aanzien van de aard of de kwaliteit van de
                      toegepaste materialen.
                    </li>
                  </ul>
                </li>
              </ol>
              <h2>Artikel 8 - Levering en uitvoering</h2>
              <ol>
                <li>
                  De ondernemer zal de grootst mogelijke zorgvuldigheid in acht
                  nemen bij het in ontvangst nemen en bij de uitvoering van
                  bestellingen van producten en bij de beoordeling van aanvragen
                  tot verlening van diensten.
                </li>
                <li>
                  Als plaats van levering geldt het adres dat de consument aan
                  het bedrijf kenbaar heeft gemaakt.
                </li>
                <li>
                  Met inachtneming van hetgeen hierover in artikel 4 van deze
                  algemene voorwaarden is vermeld, zal het bedrijf geaccepteerde
                  bestellingen met bekwame spoed doch uiterlijk binnen 14 dagen
                  uitvoeren tenzij een langere leveringstermijn is afgesproken.
                  Indien de bezorging vertraging ondervindt, of indien een
                  bestelling niet dan wel slechts gedeeltelijk kan worden
                  uitgevoerd, ontvangt de consument hiervan uiterlijk 14 dagen
                  nadat hij de bestelling geplaatst heeft bericht. De consument
                  heeft in dat geval het recht om de overeenkomst zonder kosten
                  te ontbinden en recht op eventuele schadevergoeding.
                </li>
                <li>
                  Alle levertermijnen zijn indicatief. Aan eventuele genoemde
                  termijnen kan de consument geen rechten ontlenen.
                  Overschrijding van een termijn geeft de consument geen recht
                  op schadevergoeding.
                </li>
                <li>
                  In geval van ontbinding conform het vorige lid zal de
                  ondernemer het bedrag dat de consument betaald heeft zo
                  spoedig mogelijk, doch uiterlijk binnen 14 dagen na
                  ontbinding, terugbetalen.
                </li>
                <li>
                  Indien levering van een besteld product onmogelijk blijkt te
                  zijn, zal de ondernemer zich inspannen om een vervangend
                  artikel beschikbaar te stellen. Uiterlijk bij de bezorging zal
                  op duidelijke en begrijpelijke wijze worden gemeld dat een
                  vervangend artikel wordt geleverd. Bij vervangende artikelen
                  kan het herroepingsrecht niet worden uitgesloten. De kosten
                  van een eventuele retourzending zijn voor rekening van de
                  ondernemer.
                </li>
                <li>
                  Het risico van beschadiging en/of vermissing van producten
                  berust bij de ondernemer tot het moment van bezorging aan de
                  consument of een vooraf aangewezen en aan de ondernemer bekend
                  gemaakte vertegenwoordiger, tenzij uitdrukkelijk anders is
                  overeengekomen.
                </li>
              </ol>
              <h2>
                Artikel 9 - Duurtransacties: duur, opzegging en verlenging
              </h2>
              <ol>
                <li className="list-none">
                  <ol>
                    <li>
                      De consument kan een overeenkomst die voor onbepaalde tijd
                      is aangegaan en die strekt tot het geregeld afleveren van
                      diensten, te allen tijde opzeggen met inachtneming van
                      daartoe overeengekomen opzeggingsregels en een
                      opzegtermijn van ten hoogste één maand.
                    </li>
                    <li>
                      De consument kan een overeenkomst die voor bepaalde tijd
                      is aangegaan en die strekt tot het geregeld afleveren
                      diensten, te allen tijde tegen het einde van de bepaalde
                      duur opzeggen met inachtneming van daartoe overeengekomen
                      opzeggingsregels en een opzegtermijn van ten hoogste één
                      maand.
                    </li>
                    <li>
                      De consument kan de in de vorige leden genoemde
                      overeenkomsten:
                      <ul>
                        <li>
                          ten allen tijde opzeggen en niet beperkt worden tot
                          opzegging op een bepaald tijdstip of in een bepaalde
                          periode;
                        </li>
                        <li>
                          tenminste opzeggen op dezelfde wijze als zij door hem
                          zijn aangegaan;
                        </li>
                        <li>
                          altijd opzeggen met dezelfde opzegtermijn als de
                          ondernemer voor zichzelf heeft bedongen.
                        </li>
                      </ul>
                    </li>
                  </ol>
                </li>
              </ol>
              <strong>Verlenging</strong>
              <ol>
                <li className="list-none">
                  <ol>
                    <li>
                      Een overeenkomst die voor bepaalde tijd is aangegaan en
                      die strekt tot het geregeld afleveren van diensten, mag
                      niet stilzwijgend worden verlengd of vernieuwd voor een
                      bepaalde duur.
                    </li>
                    <li>
                      In afwijking van het vorige lid mag een overeenkomst die
                      voor bepaalde tijd is aangegaan en die strekt tot het
                      geregeld afleveren van dag- nieuws- en weekbladen en
                      tijdschriften stilzwijgend worden verlengd voor een
                      bepaalde duur van maximaal drie maanden, als de consument
                      deze verlengde overeenkomst tegen het einde van de
                      verlenging kan opzeggen met een opzegtermijn van ten
                      hoogste één maand.
                    </li>
                    <li>
                      Een overeenkomst die voor bepaalde tijd is aangegaan en
                      die strekt tot het geregeld afleveren van diensten, mag
                      alleen stilzwijgend voor onbepaalde duur worden verlengd
                      als de consument te allen tijde mag opzeggen met een
                      opzegtermijn van ten hoogste één maand en een opzegtermijn
                      van ten hoogste drie maanden in geval de overeenkomst
                      strekt tot het geregeld, maar minder dan eenmaal per
                      maand, afleveren van dag-, nieuws- en weekbladen en
                      tijdschriften.
                    </li>
                    <li>
                      Een overeenkomsten met beperkte duur tot het geregeld ter
                      kennismaking afleveren van dag-, nieuws- en weekbladen en
                      tijdschriften (proef- of kennismakingsabonnement) wordt
                      niet stilzwijgend voortgezet en eindigt automatisch na
                      afloop van de proef- of kennismakingsperiode.
                    </li>
                  </ol>
                </li>
              </ol>
              <strong>Duur</strong>
              <ol>
                <li>
                  Als een overeenkomst een duur van meer dan een jaar heeft, mag
                  de consument na een jaar de overeenkomst te allen tijde met
                  een opzegtermijn van ten hoogste een maand opzeggen, tenzij de
                  redelijkheid en billijkheid zich tegen opzegging vóór het
                  einde van de overeengekomen duur verzetten.
                </li>
              </ol>
              <h2>Artikel 10 - Betaling</h2>
              <ol>
                <li>
                  Voor zover niet anders is overeengekomen, dienen de door de
                  consument verschuldigde bedragen te worden voldaan binnen 14
                  dagen na het ingaan van de bedenktermijn als bedoeld in
                  artikel 6 lid 1. In geval van een overeenkomst tot het
                  verlenen van een dienst, vangt deze termijn aan nadat de
                  consument de bevestiging van de overeenkomst heeft ontvangen.
                </li>
                <li>
                  De consument heeft de plicht om onjuistheden in verstrekte of
                  vermelde betaalgegevens onverwijld aan de ondernemer te
                  melden.
                </li>
                <li>
                  In geval van wanbetaling van de consument heeft de ondernemer
                  behoudens wettelijke beperkingen, het recht om de vooraf aan
                  de consument kenbaar gemaakte redelijke kosten in rekening te
                  brengen.
                </li>
              </ol>
              <h2>Artikel 11 - Geschillen</h2>
              <ol>
                <li>
                  Op overeenkomsten tussen de ondernemer en de consument waarop
                  deze algemene voorwaarden betrekking hebben, is uitsluitend
                  Nederlands recht van toepassing. Ook indien de consument
                  woonachtig is in het buitenland.
                </li>
                <li>Het Weens Koopverdrag is niet van toepassing.</li>
              </ol>
              <h2>Artikel 12 - Aanvullende of afwijkende bepalingen</h2>
              Aanvullende dan wel van deze algemene voorwaarden afwijkende
              bepalingen mogen niet ten nadele van de consument zijn en dienen
              schriftelijk te worden vastgelegd dan wel op zodanige wijze dat
              deze door de consument op een toegankelijke manier kunnen worden
              opgeslagen op een duurzame gegevensdrager.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AlgemeneVoorwaarden
